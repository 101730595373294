import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/pages/login/LoginComponent.vue'),
  },
  {
    path: '/add-customer',
    name: 'add-customer',
    component: () => import('../components/pages/create-customer/CreateCustomerComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/list-customers',
    name: 'list-customers',
    component: () => import('../components/pages/list-customer/ListCustomerComponent.vue'),
  },
  {
    path: '/edit-customer/:id',
    name: 'update-customer',
    component: () => import('../components/pages/edit-customer/EditCustomerComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/add-person',
    name: 'add-person',
    component: () => import('../components/pages/create-person/CreatePersonComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/list-persons',
    name: 'list-persons',
    component: () => import('../components/pages/list-person/ListPersonComponent.vue'),
  },
  {
    path: '/edit-person/:id',
    name: 'update-person',
    component: () => import('../components/pages/edit-person/EditPersonComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/add-project',
    name: 'add-project',
    component: () => import('../components/pages/create-project/CreateProjectComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/list-projects',
    name: 'list-projects',
    component: () => import('../components/pages/list-project/ListProjectComponent.vue'),
  },
  {
    path: '/edit-project/:id',
    name: 'update-project',
    component: () => import('../components/pages/edit-project/EditProjectComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/list-customer-projects/:id',
    name: 'list-customer-projects',
    component: () => import('../components/pages/list-project/ListProjectComponent.vue'),
  },
  {
    path: '/add-service',
    name: 'add-service',
    component: () => import('../components/pages/create-service/CreateServiceComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/list-services',
    name: 'list-services',
    component: () => import('../components/pages/list-service/ListServiceComponent.vue'),
  },
  {
    path: '/edit-service/:id',
    name: 'update-service',
    component: () => import('../components/pages/edit-service/EditServiceComponent.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/view-calendar',
    name: 'view-calendar',
    component: () => import('../components/pages/view-calendar/ViewCalendarComponent.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  NProgress.done();
});

// Navigation guard
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
