import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserPlus,
  faUserEdit,
  faTrash,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faUserPlus, faUserEdit, faTrash, faList);

Vue.component('font-awesome-icon', FontAwesomeIcon);
